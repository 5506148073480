import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { contaBancariaStore } from '@/utils/store-accessor';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let DateFieldComponent = class DateFieldComponent extends Vue {
    constructor() {
        super(...arguments);
        this.selectedContaBancaria = null;
    }
    get contasBancariasOptions() {
        return contaBancariaStore.contasBancarias;
    }
    onChangePicker(val) {
        this.$emit('input', val);
    }
    async mounted() {
        await contaBancariaStore.getContasBancarias(true);
    }
};
__decorate([
    Watch('selectedContaBancaria')
], DateFieldComponent.prototype, "onChangePicker", null);
DateFieldComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
        },
    })
], DateFieldComponent);
export default DateFieldComponent;
