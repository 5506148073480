export function newFinanceiroParcelaMovimentacao() {
    return {
        data_pagamento: '',
        valor_pago: null,
        observacao: '',
        financeiro_id: null,
        financeiro_parcela_id: null,
        company_id: null,
    };
}
